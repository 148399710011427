globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"449af2d26db577b52f3dea20bed4b487eedee19f"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import { init, browserTracingIntegration } from '@sentry/nextjs';

const isProd = process.env.BUILD_ENV === 'production';
const isSentryEnabled = process.env.SENTRY_ENABLED === 'true';

if (isSentryEnabled) {
	init({
		dsn: process.env.SENTRY_DSN,

		environment: process.env.BUILD_ENV,
		tracesSampleRate: 1, //enable 1 for each environment, will be adjusted later

		// Setting this option to true will print useful information to the console while you're setting up Sentry.
		debug: false,

		replaysOnErrorSampleRate: 1.0, //enable 1 for each environment, will be adjusted later

		replaysSessionSampleRate: 0.5, // let's start with 0.5 for each environment and adjust later

		integrations: [browserTracingIntegration()],
		initialScope: (scope) => {
			scope.setTags({ [`evo.tier`]: 'client' });
			return scope;
		},
	});
}

import('./sentry.replay').then(({ initReplay }) => initReplay());
